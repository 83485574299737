import { FRONTEND_DATA } from "../../../globals";
import _ from 'lodash';
import {
	getNodesInRangeFilteredBySelector,
	rangeCanBeModifiedByTextCommands,
	getTextStyleClassNames,
	wrapRangeWith
} from "../helpers";

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){

		let isFullyApplied = false;

		if(this.getState().isApplied) {

			const range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

			if(range) {
				
				// check if we're fully applied (when the range is fully contained by at least one h2)
				isFullyApplied = getNodesInRangeFilteredBySelector('H2', {
					range: FRONTEND_DATA.contentWindow.CargoEditor.helpers.shrinkRangeToNarrowestBoundaries(range.cloneRange()),
					parentsOnly: true
				}).length > 0;

			} else {
				isFullyApplied = true;
			}

		}

		// if not already applied, wrap with h2. Otherwise unwrap by passing null
		const customTextStyleClasses = getTextStyleClassNames();

		wrapRangeWith(isFullyApplied ? null : 'h2', {
			deleteParentFilter: node => {
				if(
					// do not split media item figcaptions
					node.nodeName !== 'FIGCAPTION'
					&& (
						// node has a text style
						customTextStyleClasses.some(className => node.classList.contains(className))
						// node is a header or styling node
						|| ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(node.nodeName)
					)
				) {
					return true;
				}
			}
		});
		
	},

	getState: function(range) {

		const h2Nodes = getNodesInRangeFilteredBySelector('H2', {
			includePartiallyContainedNodes: true,
			includeParents: true
		});

		return {
			isApplied: h2Nodes.length > 0,
			isAllowed: rangeCanBeModifiedByTextCommands(range)
		};
	}

}