import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands,
	wrapRangeWith,
	getNodesInRangeFilteredBySelector,
	getTextStyleClassNames
} from "../helpers";
import _ from 'lodash';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(typeStyle){

		let result = [];

		if(typeStyle === undefined) {
			return result;
		}

		const CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor
		let range = CargoEditor.getActiveRange();

		if(!range) {
			return result;
		}

		const {isApplied, isFullyApplied} = this.getState(range, [typeStyle]);

		// when the we haven't applied this to the full range, apply 
		if(typeStyle !== null && !isFullyApplied){

			// get a list of all classnames used by currently available text styles
			const customTextStyleClasses = getTextStyleClassNames();
			const applyingTextStyleClass = customTextStyleClasses.includes(typeStyle);

			wrapRangeWith('span', {
				deleteParentFilter: node => {
					if(
							// do not split media item figcaptions
							node.nodeName !== 'FIGCAPTION'
							&& (
								// node has a text style
								customTextStyleClasses.some(className => node.classList.contains(className))
								// node is a header or styling node
								|| ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(node.nodeName)
							)
					) {
						return true;
					}
				},
				before: () => {

					// first remove all old text-styles from our newly wrapped range
					getNodesInRangeFilteredBySelector('SPAN, A').forEach(span => {
						
						customTextStyleClasses.forEach(textStyleClassName => {
							if(span.classList.contains(textStyleClassName)) {
								span.classList.remove(textStyleClassName);

								// if no classes left, remove the entire class attribute
								if(span.classList.length === 0) {
									span.removeAttribute('class');
								}

							}
						});

					});

				},
				after: (nodes) => {

					range = CargoEditor.getActiveRange();

					// add class names
					nodes.forEach(node => {

						if(!node.classList.contains(typeStyle)) {
							// apply the text-style class to this node
							node.classList.add(typeStyle)
						}

						if(node.attributes.length === 0 && node.nodeName === 'SPAN') {
							FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(node, true);
						}

						result.push(node);

					});

				}
			});

		} else {

			CargoEditor.mutationManager.execute(function(){

				const customTextStyleClasses = getTextStyleClassNames();

				// split range and remove all headers and text style nodes
				CargoEditor.helpers.wrapRangeWith(null, {
					deleteParentFilter: node => {
						if(
							// do not split media item figcaptions
							node.nodeName !== 'FIGCAPTION'
							&& (
								// node has a text style
								customTextStyleClasses.some(className => node.classList.contains(className))
								// node is a header or styling node
								|| ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(node.nodeName)
							)
						) {
							return true;
						}
					}
				});
				
				// trim range
				CargoEditor.helpers.setActiveRange(
					CargoEditor.helpers.trimBreaksAndWhitespaceFromEdgesOfRange(CargoEditor.getActiveRange())
				);

			});

		}

		return result;


	},

	getState: (range, customTextStyleClassNames = getTextStyleClassNames()) => {

		let matches = {};
		let isFullyApplied = false;

		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		let selectionInThumbnailIndex = false;
		if(range) {

			if( range.commonAncestorContainer && range.commonAncestorContainer.parentElement.closest('[thumbnail-index]') || range.commonAncestorContainer.hasAttribute?.('thumbnail-index')){
				selectionInThumbnailIndex = true;
			}

			// only get state when we know what custom text styles we're looking for
			if(customTextStyleClassNames && customTextStyleClassNames.length > 0) {

				// get a list of all classnames used by currently available text styles
				const customTextStyleClasses = getTextStyleClassNames();

				const nodes = getNodesInRangeFilteredBySelector('A, SPAN, FIGCAPTION', {
					includeParents: true,
					includePartiallyContainedNodes: true
				}).filter(node => {

					// only use spans that contain existing text style classes
					for(let i = 0; i < node.classList.length; i++) {
						if(customTextStyleClasses.includes(node.classList[i])){
							return true;
						}
					}

					// no custom styles applied to this node. Ignore it
					return false;

				});

				let matchCounter = 0;

				// loop over all spans contained by this rangej
				nodes.forEach(node => {
					// loop over this span's classNames and see if any match the supplied list of custom text styles
					node.classList?.forEach(className => {

						if(customTextStyleClassNames.includes(className)) {
							// add a match for this node to the results
							(matches[className] = matches[className] || []).push(node);
							matchCounter++;
						}

					});

				});

				if(matchCounter > 0 && matchCounter === nodes.length) {
					isFullyApplied = true;
				}
				
			}

		}

		return {
			isAllowed: !selectionInThumbnailIndex && rangeCanBeModifiedByTextCommands(range),
	 		isApplied: Object.keys(matches).length > 0,
	 		isFullyApplied,
	 		matches
	 	}

	},
	priority: 1
 
}